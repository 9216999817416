<template>
  <div class="mod-order-export">
    <amis-renderer :schema="schema" :on-action="onAction" />
  </div>
</template>

<script>
import AmisRenderer from '@/components/AmisRenderer';
import { excelExport } from '@/utils';
import schema from './schemas/order-stats.json';
import moment from 'moment';

// 订单流水
// noinspection JSUnresolvedVariable
const pipelineColumns = [
  { label: '姓名', value: (item) => item.name },
  { label: '手机号', value: (item) => item.mobile },
  { label: '取餐人', value: (item) => item.consignee },
  { label: '取餐人手机号', value: (item) => item.consigneeMobile },
  { label: '订单总数', value: (item) => item.orderNum },
  { label: '订单总价', value: (item) => item.totalPrice },
  { label: '订单实付总价', value: (item) => item.actualPrice },
  { label: '团餐订单总数', value: (item) => item.groupOrderNum },
  {
    label: '团餐挂账',
    value: (item) => item.totalPrice - item.actualPrice || 0,
  },
];

export default {
  name: 'order-stats',
  components: { AmisRenderer },
  data() {
    return {
      schema,
    };
  },
  methods: {
    // 按钮动作回调
    async onAction(e, action, ctx) {
      let filename, columns, items;
      if (action.actionType === 'export-pipeline') {
        filename = `订单流水 - ${moment().format('YYYYMMDDHHmmss')}`;
        columns = pipelineColumns;
        const { data } = await this.$http({
          url: '/cc/order/export-pipeline',
          method: 'post',
          data: ctx,
          loading: false,
        });
        items = data.data.items;
      } else {
        return;
      }

      // 暂无数据
      if (!items || items.length === 0) {
        this.$message({
          message: '暂无数据',
          type: 'warning',
          duration: 1500,
        });
        return;
      }

      await excelExport(filename, columns, items);
    },
  },
};
</script>

<style lang="scss" scoped></style>
